import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getAccessToken } from '../../../utils/helpers';
import validate from './validate';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import { get } from '../../../utils/api/base';
import { getPropertyList, getTransactionCategory, getUnitList } from '../../../shared/helpers/getDatas';
import { generateFormInitialValue, prepareData, ucFirst } from '../../../shared/helpers';
import { transactionCreate, transactionUpdate } from '../../../utils/handleSubmit';
import { TRANSACTIONS } from '../../App/Router/api_routs';

const CreateTransaction = () => {
  const token = getAccessToken();
  const { id } = useParams();
  const [initValue, setInitValue] = useState({});
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);

  const {
    rtl,
    theme,
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const show = (color, message) => useNotification({
    // eslint-disable-next-line react/no-unstable-nested-components
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up',
  });

  const handleTransactionTypeChange = async (selectedOption, e) => {
    const name = e.name;
    await getTransactionCategory(selectedOption, token, setCategories);
  };

  const handlePropertyChange = async (selectedOption, e) => {
    const name = e.name;
    //setProperty(selectedOption.value);
    await getUnitList(token, selectedOption.value, 0, units, setUnits, false);
  };

  const handleTransactionCreateSubmit = async (values, form) => {
    let data = prepareData({ ...values }, ['amount'], []).data;
    data = { ...data };
    if(id){
      await transactionUpdate(data, token)
        .then((result)=>{
          if (result.data.status === 200) {
            show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    }else{
      await transactionCreate(data, token)
        .then((result) => {
          if (result.data.status === 201 || result.data.status === 200) {
            show('success', result.data.message);
            form.reset();
            setInitValue({});
            for (let key in data) {
              form.resetFieldState(key);
            }
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        });
    }
  };

  useEffect(async () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    await getPropertyList(token, source, setProperties);
    //setProperties()
    if (id) {
      await get(TRANSACTIONS + '/' + id, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      }).then(function (response) {
        if(response.data?.transaction){
          let data = response.data?.transaction;
          getTransactionCategory({value:data.transaction_type}, token, setCategories);
          getUnitList(token, data.property_id, 0, units, setUnits);
          const convertSelectDefault = {property: 'property_id', tenant: 'tenant_id', transaction_category: 'transaction_category_id', unit:'unit_id'};
          const mapValue = {property: 'name', tenant: 'name', transaction_category: 'name', unit:'unit_name'};
          data = generateFormInitialValue(data, convertSelectDefault, mapValue);
          data.transaction_type = {value:data.transaction_type, label:ucFirst(data.transaction_type)};
          setInitValue(data);
        }
      });
    }

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Transaction</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <CardTitleWrap>
                <CardTitle>Transaction Form</CardTitle>
                {/* <CardSubhead>Labels are above fields</CardSubhead> */}
              </CardTitleWrap>
              <Form onSubmit={(values, e) => handleTransactionCreateSubmit(values, e)}
                    initialValues={initValue}
                    validate={validate}>
                {({
                  handleSubmit,
                  form,
                  touched
                }) => {
                  return (
                    <FormContainer onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormGroupLabel>Transaction Type</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="transaction_type"
                            component={renderSelectField}
                            options={[{
                              value: 'income',
                              label: 'Income'
                            }, {
                              value: 'expense',
                              label: 'Expense'
                            }]}
                            onSelectChange={handleTransactionTypeChange}
                            placeholder="Select Transaction Type"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Transaction Category</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="transaction_category_id"
                            component={renderSelectField}
                            options={categories}
                            //onSelectChange={handlePropertyChange}
                            placeholder="Select Transaction Category"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Property</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="property_id"
                            component={renderSelectField}
                            options={properties}
                            onSelectChange={handlePropertyChange}
                            placeholder="Select Property"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Unit</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="unit_id"
                            component={renderSelectField}
                            options={units[0]}
                            //onSelectChange={handleUnitChange}
                            placeholder="Select Unit"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormGroup>
                        <FormGroupLabel>Amount</FormGroupLabel>
                        <FormGroupField>
                          <Field
                            name="amount"
                            component={FormField}
                            type="text"
                            placeholder="Amount"
                          />
                        </FormGroupField>
                      </FormGroup>
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Button variant="secondary" type="button" onClick={form.reset}>
                          Cancel
                        </Button>
                      </FormButtonToolbar>
                    </FormContainer>
                  );
                }}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default CreateTransaction;
