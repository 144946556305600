import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import renderFileInputField from '@/shared/components/form/FileInput';
import { useSelector } from 'react-redux';
import validate from './validate';
import { getAccessToken } from '../../../utils/helpers';
import { get } from '../../../utils/api/base';
import {

  GET_COUNTRIES,
  GET_PROPERTY_DETAILS,
  PROPERTY_TYPES,
  GET_PROVINCES
} from '../../App/Router/api_routs';
import axios from 'axios';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import { propertyCreate, propertyUpdate } from '../../../utils/handleSubmit';
import { generateFormInitialValue, prepareData } from '../../../shared/helpers';
import styled from 'styled-components';
import { colorAccent } from '../../../utils/palette';
import { getPropertyTypes, getProvincesList, getCountriesList } from '../../../shared/helpers/getDatas';

const AddProperty = () => {
  const token = getAccessToken();
  const [countries, setCountries] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyPreview, setPropertyPreview] = useState('');
  const { id } = useParams();
  const [initValue, setInitValue] = useState({country_id:{value:524,label:'Nepal'}});
  const [isFetching, setIsFetching] = useState(true);

  const {
    rtl,
    theme
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));
  const show = (color, message) => useNotification({
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up'
  });

  const getPropertyDetails = async (id, source) => {
    await get(GET_PROPERTY_DETAILS + `${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      },
      cancelToken: source.token
    })
      .then((response) => {
        let propertyData = response?.data?.property;
        const convertSelectDefault = {
          country: 'country_id',
          province: 'state_id',
          property_type: 'property_type_id'
        };
        const mapValue = {
          country: 'en_short_name',
          province: 'province_name',
          property_type: 'property_type'
        };
        const data = generateFormInitialValue(propertyData, convertSelectDefault, mapValue);
        if (data && data.property_image) {
          setPropertyPreview(data.property_image);
        }
        setInitValue(data);
        setIsFetching(false);
      });
  };

  const handlePropertySubmit = async (values, form) => {
    let convertIntKeys = ['country_id', 'state_id', 'status', 'total_floors', 'property_total_area', 'property_built_year'],
      propertyData = prepareData(values, convertIntKeys);
    const formData = propertyData.form_data;
    propertyData = propertyData.data;

    propertyData = { ...propertyData };

    if (propertyData && !id) {
      await propertyCreate(formData, token)
        .then((result) => {
          if (result.data.status === 201) {
            show('success', result.data.message);
            form.reset();
            for (let key in propertyData) {
              form.resetFieldState(key);
            }
            setPropertyPreview('');
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        })
        .catch(function (e) {
          show('danger', e.response.data.message);
        });
    }
    if (propertyData && id) {
      await propertyUpdate(id, formData, token)
        .then((result) => {
          if (result.data.status === 200) {
            show('success', result.data.message);
          } else {
            show('danger', result.data.errors.join('<br/>'));
          }
        })
        .catch(function (e) {
          show('danger', e.response.data.message);
        });
    }
  };

  const handleFileChange = (e, file) => {
    /*let img = new Image();
    img.src = file.preview;
    img.id = "property-preview";
    img.style = 'width:100px;height:100px';
    const prev = document.getElementById('property-preview');
    if(prev){
        prev.remove();
    }
    e.target.before(img);*/

    setPropertyPreview(file.preview);
  };

  useEffect(async () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    await getCountriesList(token, source, setCountries);
    await getProvincesList(token, source, setProvinces);
    await getPropertyTypes(token, source, setPropertyTypes);
    if (id) {
      await getPropertyDetails(id, source);
    }
    setIsFetching(false);
    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
  }, [propertyPreview, setPropertyPreview]);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Property Add Form</h3>
          {/* <h3 className="page-subhead subhead">Use this elements, if you want to show some hints or additional
                        information
                    </h3> */}
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          {isFetching ?
            <Card>
              <CardBody>
                <div className="text-center">
                  <TableSpinner animation="border"/>
                </div>
              </CardBody>
            </Card>
            :
            <div className="form-wrap">
              <Form onSubmit={(values, e) => handlePropertySubmit(values, e)}
                    initialValues={initValue}
                    validate={validate}>
                {({
                  handleSubmit,
                  form,
                  errors
                }) => {
                  return (
                    <FormContainer onSubmit={handleSubmit}>
                      <Card id="info-card">
                        <CardBody>
                          <CardTitleWrap>
                            <CardTitle>Property Information</CardTitle>
                            {/* <CardSubhead>Labels are above fields</CardSubhead> */}
                          </CardTitleWrap>
                          <FormGroup>
                            <FormGroupLabel>Name</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="name"
                                component={FormField}
                                type="text"
                                placeholder="Name"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>Address Line 1</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="address1"
                                component={FormField}
                                type="text"
                                placeholder="Address Line 1"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>Address Line 2</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="address2"
                                component={FormField}
                                type="text"
                                placeholder="Address Line 2"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>Country</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="country_id"
                                component={renderSelectField}
                                options={countries}
                                placeholder="Country"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>State</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="state_id"
                                component={renderSelectField}
                                options={provinces}
                                placeholder="State"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>City</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="city"
                                component={FormField}
                                type="text"
                                placeholder="City"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>Zip Code</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="zipcode"
                                component={FormField}
                                type="text"
                                placeholder="Zip Code"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>Property Type</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="property_type_id"
                                component={renderSelectField}
                                options={propertyTypes}
                                placeholder="Property Type"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>Year Built</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="property_built_year"
                                component={FormField}
                                type="number"
                                placeholder="Property Built Year"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>No.of Floors</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="total_floors"
                                component={FormField}
                                type="number"
                                placeholder="Total Floors"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>Area(in sq.ft)</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="property_total_area"
                                component={FormField}
                                type="number"
                                placeholder="(Area in sq.ft)"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>Status</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="status"
                                component={renderSelectField}
                                options={[{
                                  value: 1,
                                  label: 'Active'
                                }, {
                                  value: 2,
                                  label: 'Inactive'
                                }]}
                                placeholder="Status"
                              />
                            </FormGroupField>
                          </FormGroup>
                          <FormGroup>
                            <FormGroupLabel>Property Image</FormGroupLabel>
                            <FormGroupField>
                              <Field
                                name="property_image"
                                component={renderFileInputField}
                                onFileChange={handleFileChange}
                                preview={propertyPreview}
                              />
                            </FormGroupField>
                          </FormGroup>
                        </CardBody>
                      </Card>
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">Submit</Button>
                        <Button variant="secondary" type="button" onClick={form.reset}>
                          Cancel
                        </Button>
                      </FormButtonToolbar>
                    </FormContainer>
                  );
                }}
              </Form>
            </div>
          }

        </Col>
      </Row>
    </Container>
  );
};
export default AddProperty;

const TableSpinner = styled(Spinner)`
    color: ${colorAccent};
`;
