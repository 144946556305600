import { React, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { deleteRequest, get } from '../../utils/api/base';
import { DELETE_USER, GET_USERS } from '../App/Router/api_routs';
import { getAccessToken } from '../../utils/helpers';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import ListTable from './Components/list';
import ConfirmBox from '../../shared/components/Modal/confirm';
import { Button } from '@/shared/components/Button';
import { useSelector } from 'react-redux';
import { useNotification, BasicNotification } from '../../shared/components/Notification';


export const UserList = () => {
    const module = "User";
    const token = getAccessToken();
    const [isFetching, setIsFetching] = useState(true);
    const [data, setData] = useState({});
    const [totalData, setTotalData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);


    const history = useHistory();
    const editData = (id) => {
        let path = `edit/${id}`;
        history.push(path);
    }

    const { rtl, theme } = useSelector(state => ({
        rtl: state.rtl,
        theme: state.theme,
    }));

    const show = (color, message) => useNotification({
      notification() {
        return (
          <BasicNotification
            color={color}
            title="User"
            message={message}
            theme={theme}
          />
        );
      },
      position: 'right-up'
    });

    const deleteClickHandle = (e, userId) => {
        setShowHideConfirmBox(userId);
    }

    const deleteUser = (userId) => {
        deleteRequest(DELETE_USER + '/' + userId, {
            headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' }
        }).then(function (response) {
            if (response?.status == 200) {
                loadData();
                setShowHideConfirmBox(false);
                show('success', response.data.message);
            } else {
                show('info', response.data.errors.join('<br/>'));
            }
        }).catch(function (e) {
            show('info', e?.message);
        });
    }

    const loadData = async () => {
        let urlParams = `?page=${currentPage}&limit=${pageSize}`;
        const result = await get(GET_USERS + urlParams, {
            headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' }
        })
        if (result) {
            setData([]);//set data does not worked unless set this empty.
            setData(result?.data?.users?.data);
            setTotalData(result?.data?.users?.total);
            setIsFetching(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [currentPage, pageSize]);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Phone',
                accessor: 'user_details.primary_contact_number',
            },
            {
                Header: 'Address',
                accessor: 'user_details.primary_address_line1',
            },
            {
                Header: 'State',
                accessor: 'user_details.primary_state_id',
            },
            {
                Header: 'City',
                accessor: 'user_details.primary_city'
            },
            {
                Header: "Actions",
                accessor: "[actionButtons]",
                Cell: (cellObj) => (
                    <>
                        <Button variant="outline-primary" size="sm" marginBottom="0" onClick={() => editData(cellObj.row.original.id)}>Edit</Button>
                        <Button variant="outline-danger" size="sm" marginBottom="0" onClick={(e) => deleteClickHandle(e, cellObj.row.original.id)} >Delete</Button>
                    </>
                )
            }
        ]
    );

    const pageSettings = ({
        total: totalData,
        currentPage,
        limit: pageSize,
        customPagination: true,
    });

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{module} Information</h3>
                </Col>
            </Row>
            {showHideConfirmBox && (
                <Row>
                    <ConfirmBox
                        color='primary'
                        message='Are you sure you want to delete?'
                        showHide={showHideConfirmBox}
                        onChangeShowHide={setShowHideConfirmBox}
                        callbackConfirm={deleteUser} />
                </Row>
            )}
            <Row>
                {isFetching && (
                    <div className="text-center">
                        <TableSpinner animation="border" />
                    </div>
                )}
                {data && data.length > 0 && !isFetching && (
                    <Col md={12}>
                        <ListTable
                            reactTableData={{ tableHeaderData: columns, tableRowsData: data, pageSettings: pageSettings }}
                            module={module}
                            setPage={(page) => setCurrentPage(page)}
                            setLimit={(limit) => setPageSize(limit)}
                            showNotification={show}
                        />
                    </Col>
                )}
            </Row>
        </Container>
    )
}


const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;
