/* eslint-disable */

import { get } from '../../../utils/api/base';
import {  GET_USER_DETAILS_BY } from '../../App/Router/api_routs';
import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';
import { UserRoles } from '../../../shared/constants/userroles';

const getUserDetailsBy = (url) => {
  const auth = localStorage.getItem('pmf') ? JSON.parse(localStorage.getItem('pmf')) : '';
  const token = auth?.accessToken;
  return get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
};
const checkAlreadyExist = async (api_url, values) => {
  const url = api_url;
  let finalUrl = url + values.primary_email;
  if (values.hasOwnProperty('id')) {
    finalUrl = url + values.id;
  }
  const response = await getUserDetailsBy(finalUrl);
  const user_details = response?.data?.user_details;
  return !!((user_details && !values.hasOwnProperty('id')) ||
    (user_details && values.hasOwnProperty('id') && user_details.id === values.id
      && user_details.email !== values.primary_email && ((await getUserDetailsBy(url + values.primary_email))?.data?.user_details)));
};

const validate = async (values) => {
  await sleep(100);
  const errors = {};
  if (!values.property) {
    errors.property = 'Name field is required.';
  }
  if (!values.unit) {
    errors.unit = 'Name field is required.';
  }
  /***************************** facility validation start ***********************************/
  if (!values.facilities || !values.facilities.length) {
    errors.facilities = 'Required at least one facility.';
  }
  if (values.facilities) {
    const facilitiesErrors = [];

    values.facilities.forEach((facility, index) => {
      const facilityErrors = {};

      if (!facility.facility_id) {
        facilityErrors.facility_id = 'Facility field is Required.';
      }

      if (!facility.facility_type) {
        facilityErrors.facility_type = 'Facility type field is Required.';
      }

      if (Object.keys(facilityErrors).length) {
        facilitiesErrors[index] = facilityErrors;
      }
    });
    if (facilitiesErrors.length) {
      errors.facilities = facilitiesErrors;
    }
  }
  /***************************** facility validation end ***********************************/

  return errors;
};

export default validate;
