import React, { useContext, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import { Button, ButtonToolbar } from '@/shared/components/Button';
import { Table } from '@/shared/components/TableElements';
import { colorAdditional, colorBackground, logoImg } from '@/utils/palette';
import {
  marginRight,
  marginLeft,
  left,
  right,
} from '@/utils/directions';
import { State } from './context/stateContext';
import { getInvoice, getSettingsValue } from '../../../../shared/helpers/getDatas';
import { getAccessToken } from '../../../../utils/helpers';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { ReactToPrint } from 'react-to-print';

const InvoiceTemplate = (inv = null) => {
  const token = getAccessToken();
  const { id } = useParams();
  const history = useHistory();
  const {
    invoice,
    setInvoice,
    componentRef,
    handlePrint,
  } = useContext(State);

  const backHandle = () => {
    let path = `/invoice/list`;
    history.push(path);
  };

  useEffect(async () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    let invId = id;
    if (inv && inv?.invoiceId) {
      invId = inv?.invoiceId;
    }
    let invoiceData = await getInvoice(invId, token, source);
    setInvoice(invoiceData);

    return () => {
      source.cancel();
    };
  }, []);

  let subTotal = 0;
  return (
    <>
      <DownloadButtonWrap>
        <ReactToPrint
          trigger={() => (
            <Button variant="success" size="sm">
              Print / Download <span className="lnr lnr-printer" style={{ marginLeft: 5 }}></span>
            </Button>
          )}
          content={() => componentRef.current}
        />
        <Button variant="danger" size="sm" onClick={backHandle}><span
          className="lnr lnr-arrow-left"></span> Back</Button>
      </DownloadButtonWrap>
      <div ref={componentRef}>
        <Col md={12} lg={12}>
          <Card>
            {invoice &&
              <InvoiceCardBody>
                <InvoiceHead>
                  <InvoicerDetails>
                    <InvoiceLogo/>
                    <p><strong>{invoice.property.name}</strong></p>
                    <p>{invoice.property.address1}</p>
                    {invoice.property.address2 && <p>{invoice.property.address1}</p>}
                    <p>{invoice.property.zipcode}</p>
                    {invoice.property.city &&
                      <p>{invoice.property.city}{invoice.property.country.en_short_name && <>, {invoice.property.country.en_short_name}</>}</p>}
                  </InvoicerDetails>
                  <InvoiceHeadRight className="flex flex-col items-end justify-end">
                    <InvoiceHeadList>
                      <InvoiceHeadListItem><h4>Invoice <strong>#{invoice.invoice_number}</strong></h4>
                      </InvoiceHeadListItem>
                      <InvoiceHeadListItem><InvoiceDate>{invoice.created_date}</InvoiceDate></InvoiceHeadListItem>
                      <InvoiceHeadListItem>{invoice.user?.name}</InvoiceHeadListItem>
                      <InvoiceHeadListItem>Tenant at <strong>{invoice.unit.unit_name}</strong></InvoiceHeadListItem>
                      <InvoiceHeadListItem>{invoice.user?.email}</InvoiceHeadListItem>
                      <InvoiceHeadListItem>{invoice.user?.user_details.primary_address_line1}</InvoiceHeadListItem>
                      <InvoiceHeadListItem>{invoice.user?.user_details.primary_city}, {invoice.user?.user_details.primary_country.en_short_name}</InvoiceHeadListItem>
                    </InvoiceHeadList>
                  </InvoiceHeadRight>
                </InvoiceHead>
                <Table bordered responsive>
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Facility</th>
                    <th>Current Unit</th>
                    <th>Previous Unit</th>
                    <th>Total Consume</th>
                    <th>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  {invoice.details.map((item, index) => {
                    subTotal = parseFloat(subTotal) + parseFloat(item.amount);
                    return (
                      <tr key={`index_${item.id}`}>
                        <td>{index + 1}</td>
                        <td>{item.facility.facility}</td>
                        <td>{item.type?.electricity_type}{item.type?.water_type}</td>
                        <td>{item.current_unit}</td>
                        <td>{item.previous_unit}</td>
                        <td>Rs {item.amount}</td>
                      </tr>
                    );
                  })}
                  <tr style={{ border: 0 }}>
                    <th colSpan={5} align={'right'} style={{ paddingBottom: 0, textAlign:'right' }}>Sub Total :</th>
                    <th style={{ paddingBottom: 0 }}>Rs {subTotal}</th>
                  </tr>
                  <tr style={{ border: 0 }}>
                    <th colSpan={5} align={'right'} style={{ paddingBottom: 0, textAlign:'right' }}>Rent :</th>
                    <th style={{ paddingBottom: 0 }}>Rs {invoice.rent}</th>
                  </tr>
                  <tr style={{ border: 0 }}>
                    <th colSpan={5} align={'right'} style={{ paddingBottom: 0, textAlign:'right' }}>Grand Total :</th>
                    <th style={{ paddingBottom: 0 }}>Rs {subTotal + parseFloat(invoice.rent)}</th>
                  </tr>
                  </tbody>
                </Table>
                {/*<InvoiceTotal>
                  <InvoiceTotalInner>
                    <InvoiceTotalItem>
                      <span>Sub - Total amount</span> :
                      <span>Rs {subTotal}</span>
                    </InvoiceTotalItem>
                    <InvoiceTotalItem>
                      <span>Rent</span>:
                      <span>{invoice.rent}</span>
                    </InvoiceTotalItem>
                    <InvoiceTotalItem>
                      <span><InvoiceGrandTotal>Grand Total</InvoiceGrandTotal></span>:
                      <span><InvoiceGrandTotal>{subTotal + parseFloat(invoice.rent)}</InvoiceGrandTotal></span>
                    </InvoiceTotalItem>
                  </InvoiceTotalInner>
                </InvoiceTotal>*/}
                <InvoiceFooter>
                  <InvoiceFooterInner>
                    <p>{getSettingsValue('company_name')}</p>
                    <p>{getSettingsValue('company_address')
                        ? <>Address:{getSettingsValue('company_address')}</>
                        : ''}
                      {getSettingsValue('company_email')
                        ? <>, Email: {getSettingsValue('company_email')}</>
                        : ''}
                      </p>
                  </InvoiceFooterInner>
                </InvoiceFooter>
              </InvoiceCardBody>
            }
          </Card>
        </Col>
      </div>
    </>
  );
};

export default InvoiceTemplate;

const DownloadButtonWrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    justify-content: flex-end;
    padding-right: 50px;
`;

// region STYLES
const InvoiceCardBody = styled(CardBody)`
    padding: 35px;
    text-align: ${left};
    background-color: ${colorBackground};

    @media screen and (min-width: 768px) {
        padding: 50px;
    }
`;

const InvoiceHead = styled.div`
    clear: both;
    padding-bottom: 50px;

    p {
        color: ${colorAdditional};
    }

    &::after {
        content: "";
        clear: both;
        display: table;
    }
`;

const InvoicerDetails = styled.div`
    float: left;
`;

const InvoiceHeadRight = styled.div`
    width: 100%;
    margin-top: 30px;
    justify-content: end;
    align-items: end;

    @media screen and (min-width: 768px) {
        width: auto;
        text-align: ${right};
        margin-top: 0;
    }
`;

const InvoiceHeadList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    float: right;
`;

const InvoiceHeadListItem = styled.li`

`;

const InvoiceDate = styled.p`
    margin-bottom: 10px;
`;
/*
const InvoiceTotal = styled.div`
    text-align: ${right};
    margin-top: 15px;
    clear: both;

    &::after {
        content: "";
        clear: both;
        display: table;
    }
`;

const InvoiceTotalInner = styled.div`
    float: right;
    width: 500px;
    text-align: left;
`;

const InvoiceTotalItem = styled.div`
    background-color: red;
    span {
        display: inline-block;
        width: 150px;
    }
`;

const InvoiceGrandTotal = styled.span`
    font-weight: 500;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 30px;
`;*/

const InvoiceToolbar = styled(ButtonToolbar)`
    justify-content: flex-end;

    button {
        ${marginLeft}: 15px;
        ${marginRight}: 0;
    }
`;

const InvoiceLogo = styled.div`
    width: 120px;
    height: 16px;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: left;
    background-size: contain;
    background-image: ${logoImg};
`;

const InvoiceFooter = styled.div`
    padding-top: 100px;
`;

const InvoiceFooterInner = styled.div`
    text-align: center;
    border-top: 1px solid #ccc;
    padding: 5px 0 50px;
`;

// endregion
