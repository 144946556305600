import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField, FormGroupIcon,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import { useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import axios from 'axios';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { getAccessToken } from '../../../utils/helpers';
import validate from './validate';
import { BasicNotification, useNotification } from '../../../shared/components/Notification';
import ConfirmBox from '../../../shared/components/Modal/confirm';
import {
  getFacilityList,
  getInvoiceDetail,
  getLeasedPropertyList,
  getRentByPropertyUnit,
  getUnitList,
} from '../../../shared/helpers/getDatas';
import { prepareData, rand } from '../../../shared/helpers';
import { invoiceSave, invoiceDetailsCreate, invoiceDetailsSave } from '../../../utils/handleSubmit';
import { INVOICE_DETAILS_BY_INVOICE_ID, INVOICES } from '../../App/Router/api_routs';
import { deleteRequest } from '../../../utils/api/base';
import styled from 'styled-components';

const CreateInvoice = () => {
  const token = getAccessToken();
  const { id } = useParams();
  const [initValue, setInitValue] = useState({ facilities: [{}] });
  const [showFacilityDeleteConfirmBox, setShowFacilityDeleteConfirmBox] = useState(false);
  const [properties, setProperties] = useState([]);
  const [units, setUnits] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [facilityTypes, setFacilityTypes] = useState([]);
  const [property, setProperty] = useState('');
  const [rent, setRent] = useState(0);
  const [userId, setUserId] = useState('');
  const [facility, setFacility] = useState([]);
  const [facilityType, setFacilityType] = useState([]);
  const [fieldChange, setFieldChange] = useState('');
  const [currentUnit, setCurrentUnit] = useState([]);
  const [previousUnit, setPreviousUnit] = useState([]);
  const [changedIndex, setChangedIndex] = useState(0);
  const [userFacilityUnits, setUserFacilityUnits] = useState([]);
  const [totalConsumedUnits, setTotalConsumedUnits] = useState([]);
  const [totalAmount, setTotalAmount] = useState([]);
  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [minimumCharge, setMinimumCharge] = useState([]);

  const {
    rtl,
    theme,
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const show = (color, message) => useNotification({
    // eslint-disable-next-line react/no-unstable-nested-components
    notification() {
      return (
        <BasicNotification
          color={color}
          title="Property"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up',
  });

  const handlePropertyChange = async (selectedOption, e) => {
    const name = e.name;
    setProperty(selectedOption.value);
    await getUnitList(token, selectedOption.value, 0, units, setUnits);
  };

  const handleUnitChange = async (selectedOption, e) => {
    const name = e.name;
    await getRentByPropertyUnit(token, property, selectedOption.value, setRent, setUserId);
  };

  const handleFacilityChange = async (selectedOption, e) => {
    const name = e.name;
    const index = parseInt((name.split('[')[1].split(']')[0]));
    facility[index] = selectedOption.value;
    setFacility(facility);
    //await getFacilityType(token, selectedOption.value, index, facilityTypes, setFacilityTypes);
  };

  const handleFacilityTypeChange = (selectedOption, e) => {
    const name = e.name;
    const index = parseInt((name.split('[')[1].split(']')[0]));
    let ft = facilityType;
    ft[index] = selectedOption.value;
    setFacilityType(ft);
    setChangedIndex(index);
    //getUserFacilityUnits(token, facility[index], selectedOption.value, userId, index, userFacilityUnits, setUserFacilityUnits, previousUnit, setPreviousUnit, minimumCharge, setMinimumCharge);
    totalConsumedUnits[index] = currentUnit[index] ? calculateConsumedUnit(currentUnit[index], previousUnit[index]) : 0;
    setTotalConsumedUnits(totalConsumedUnits);
    amountCalculation();
    setFieldChange(rand(3));
  };

  const currentUnitChangeHandle = (e, i) => {
    //getUserFacilityUnits(token, facility[i], facilityType[i], userId, i, userFacilityUnits, setUserFacilityUnits, previousUnit, setPreviousUnit, minimumCharge, setMinimumCharge);
    let cu = currentUnit;
    cu[i] = e.target.value ? parseInt(e.target.value) : 0;
    setCurrentUnit(cu);
    setChangedIndex(i);
    totalConsumedUnits[i] = currentUnit[i] ? calculateConsumedUnit(currentUnit[i], previousUnit[i]) : 0;
    setTotalConsumedUnits(totalConsumedUnits);
    amountCalculation();
    setFieldChange(rand(3));
  };

  const handleFacilityDelete = (fields, i, form) => {
    if (fields.value[i].id) {
      setShowFacilityDeleteConfirmBox({
        fields: fields,
        index: i
      });
    } else {
      fields.remove(i);
      delete currentUnit[i];
      delete previousUnit[i];
      delete totalConsumedUnits[i];
      delete totalAmount[i];
      totalCalculation();
    }
  };

  const handleInvoiceCreateSubmit = async (values, form) => {
    let data = prepareData({ ...values }, [], ['facilities']).data;
    let invoiceData = {
      rent: rent,
      property_id: data.property,
      unit_id: data.unit,
      user_id: userId,
    };
    const facilities = values.facilities;
    if (id) {
      invoiceData.id = parseInt(id);
      invoiceData.status = data.status;
      await deleteRequest(INVOICE_DETAILS_BY_INVOICE_ID + '/' + invoiceData.id, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      })
        .then((response) => {
          console.log(response.data);
        });
    }
    await invoiceSave(invoiceData, token)
      .then((result) => {
        if (result.data.status === 201 || result.data.status === 200) {
          const invoice_id = result.data.invoice.id;
          let invoiceDetails = {};
          for (const i in facilities) {
            invoiceDetails = prepareData(facilities[i], [], []).data;
            invoiceDetails.invoice_id = invoice_id;
            invoiceDetails.current_unit = currentUnit[i];
            invoiceDetails.previous_unit = previousUnit[i];
            invoiceDetails.total_consumed_unit = totalConsumedUnits[i];
            invoiceDetails.amount = totalAmount[i];
            let prepareInvoiceDetailData = { ...invoiceDetails };
            invoiceDetailsCreate(prepareInvoiceDetailData, token)
              .then((result) => {
                //console.log(result);
              });
          }
          show('success', result.data.message);
          if (!id) {
            setInitValue({ facilities: [{}] });
            setCurrentUnit([0]);
            setPreviousUnit([0]);
            setTotalConsumedUnits([0]);
            setTotalAmount([0]);
            setRent(0);
            setTotal(0);
            setGrandTotal(0);
            setMinimumCharge(0);
            form.reset(values);
          }
        } else {
          show('danger', result.data.errors.join('<br/>'));
        }
      });

  };

  const confirmDeleteFacility = (obj) => {
    const fields = obj.fields;
    const i = obj.index;
    fields.remove(i);
    setShowFacilityDeleteConfirmBox(false);
    /*deleteRequest(INVOICES + '/' + fields.value[i].id, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(function (response) {
        if (response.data.status === 200) {
          setShowDocumentDeleteConfirmBox(false);
          fields.remove(i);
          show('success', response.data.message);
        } else {
          show('info', response.data.errors.join('<br/>'));
        }
      })
      .catch(function (e) {
        show('info', e?.message);
      });*/
  };

  useEffect(async () => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    await getLeasedPropertyList(token, source, setProperties);
    if (userId) {
      await getFacilityList(token, source, userId, setFacilities);
    }
    if (id && !userId) {
      await getInvoiceDetail(token, id, source, setInitValue,
        currentUnit,
        setCurrentUnit,
        previousUnit,
        setPreviousUnit,
        totalConsumedUnits,
        setTotalConsumedUnits,
        totalAmount,
        setTotalAmount,
        setRent,
        setTotal,
        setGrandTotal,
        setUserId);
    }
    return () => {
      source.cancel();
    };
  }, [userId]);

  useEffect(() => {

  }, [fieldChange]);

  const calculateConsumedUnit = (cUnit, pUnit) => {
    if (cUnit === '' || cUnit === undefined || pUnit === undefined) {
      return 0;
    }
    return parseInt(cUnit) - parseInt(pUnit);
  };

  const calculateAmount = (units, totalConsumed) => {
    return (parseInt(totalConsumed) * parseInt(units.per_unit) + parseFloat(units.minimum_charge));
  };

  const amountCalculation = () => {
    if (userFacilityUnits[changedIndex] && userFacilityUnits[changedIndex] !== undefined) {
      const userUnits = userFacilityUnits[changedIndex];
      let filterUserUnits = userUnits.filter((item) => item.range_to >= totalConsumedUnits[changedIndex]);
      if (filterUserUnits.length < 1) {
        filterUserUnits = userUnits[userUnits.length - 1]; //get large units if consumed unit not matched
      } else {
        filterUserUnits = filterUserUnits[0]; //get smallest units if consumed unit matched
      }
      totalAmount[changedIndex] = calculateAmount(filterUserUnits, totalConsumedUnits[changedIndex]);
      setTotalAmount(totalAmount);
      totalCalculation();
    }
  };

  const totalCalculation = () => {
    const total = totalAmount.length > 1 ? totalAmount.reduce((a, b) => a + b) : totalAmount;
    setTotal(total);
    setGrandTotal(parseFloat(total) + parseFloat(rent));
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Invoice Create</h3>
          {/* <h3 className="page-subhead subhead">Use these elements, if you want to show some hints or additional
                        information
                    </h3> */}
        </Col>
      </Row>
      <Row>
        <Col md={11} lg={11}>
          <div className="form-wrap" data-token={token}>
            <Form
              onSubmit={(values, e) => handleInvoiceCreateSubmit(values, e)}
              initialValues={initValue}
              mutators={{ ...arrayMutators }}
              validate={validate}
            >
              {({
                handleSubmit,
                form,
                errors,
                reset,
              }) => {
                return (
                  <FormContainer onSubmit={handleSubmit}>
                    <Card id="info-card">
                      <CardBody>
                        <CardTitleWrap>
                          <CardTitle>Invoice</CardTitle>
                          {/* <CardSubhead>Labels are above fields</CardSubhead> */}
                        </CardTitleWrap>
                        {showFacilityDeleteConfirmBox && (
                          <Row>
                            <ConfirmBox
                              color="primary"
                              message="Are you sure you want to delete?"
                              showHide={showFacilityDeleteConfirmBox}
                              onChangeShowHide={setShowFacilityDeleteConfirmBox}
                              callbackConfirm={confirmDeleteFacility}/>
                          </Row>
                        )}
                        <Row>
                          <Col xl={4}>
                            <FormGroup className="horizontal">
                              <FormGroupLabel>Property</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="property"
                                  component={renderSelectField}
                                  options={properties}
                                  onSelectChange={handlePropertyChange}
                                  placeholder="Select Property"
                                />
                              </FormGroupField>
                            </FormGroup>
                            <FormGroup className="horizontal">
                              <FormGroupLabel>Unit</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="unit"
                                  component={renderSelectField}
                                  options={units[0]}
                                  onSelectChange={handleUnitChange}
                                  placeholder="Select Unit"
                                />
                              </FormGroupField>
                            </FormGroup>
                            <FormGroup className="horizontal">
                              <FormGroupLabel>Rent</FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="rent"
                                  component={FormField}
                                  type="number"
                                  //onChange={(e)=>setRent(e.target.value)}
                                  input={{
                                    name: 'rent',
                                    value: rent
                                  }}
                                  placeholder="Rent"
                                  readOnly="readonly"
                                />
                              </FormGroupField>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-5">
                          <Col md={12}>
                            <FormGroup>
                              {/*{showPropertyDeleteConfirmBox && (
                                <Row>
                                  <ConfirmBox
                                    color="primary"
                                    message="Are you sure you want to delete?"
                                    showHide={showPropertyDeleteConfirmBox}
                                    onChangeShowHide={setShowPropertyDeleteConfirmBox}
                                    callbackConfirm={confirmDeleteProperty}/>
                                </Row>
                              )}*/}
                              <FieldArray name="facilities">
                                {({ fields }) => (
                                  <>
                                    {
                                      fields.map((name, i) => (
                                        <InvoiceRow key={i}>
                                          <InvoiceCol>
                                            <FormGroup>
                                              <FormGroupLabel>Facility</FormGroupLabel>
                                              <FormGroupField>
                                                <Field
                                                  name={`${name}.facility_id`}
                                                  component={renderSelectField}
                                                  options={facilities}
                                                  onSelectChange={handleFacilityChange}
                                                  placeholder="Select Facility"
                                                />
                                              </FormGroupField>
                                            </FormGroup>
                                          </InvoiceCol>
                                          <InvoiceCol>
                                            <FormGroup>
                                              <FormGroupLabel>Type</FormGroupLabel>
                                              <FormGroupField>
                                                <Field
                                                  name={`${name}.facility_type`}
                                                  component={renderSelectField}
                                                  options={facilityTypes[i]}
                                                  onSelectChange={handleFacilityTypeChange}
                                                  placeholder="Select Facility Type"
                                                />
                                              </FormGroupField>
                                            </FormGroup>
                                          </InvoiceCol>
                                          <InvoiceCol>
                                            <FormGroup>
                                              <FormGroupLabel>Current Unit</FormGroupLabel>
                                              <FormGroupField>
                                                <Field
                                                  name={`${name}.current_unit`}
                                                  type="number"
                                                  component={FormField}
                                                  onChange={(e) => {
                                                    currentUnitChangeHandle(e, i);
                                                  }}
                                                  input={{
                                                    name: `${name}.current_unit`,
                                                    value: currentUnit[i] ?? 0
                                                  }}
                                                  placeholder="Current Unit"
                                                />
                                              </FormGroupField>
                                            </FormGroup>
                                          </InvoiceCol>
                                          <InvoiceCol>
                                            <FormGroup>
                                              <FormGroupLabel>Previous Unit</FormGroupLabel>
                                              <FormGroupField>
                                                <Field
                                                  name={`${name}.previous_unit`}
                                                  component={FormField}
                                                  type="number"
                                                  /*onChange={(e) =>{
                                                    previousUnit[i] = e.target.value;
                                                    setPreviousUnit(previousUnit);
                                                    setFieldChange(e.target.value);
                                                    setChangedIndex(i);
                                                  }}*/
                                                  input={{
                                                    name: `${name}.previous_unit`,
                                                    value: previousUnit[i] ?? 0
                                                  }}
                                                  placeholder="Previous Unit"
                                                  readOnly="readonly"
                                                />
                                              </FormGroupField>
                                            </FormGroup>
                                          </InvoiceCol>
                                          <InvoiceCol>
                                            <FormGroup>
                                              <FormGroupLabel>Total Consume</FormGroupLabel>
                                              <FormGroupField>
                                                <Field
                                                  name={`${name}.total_consumed_unit`}
                                                  component={FormField}
                                                  placeholder="Total Consume Unit"
                                                  type="number"
                                                  readOnly="readonly"
                                                  input={{
                                                    name: `${name}.total_consumed_unit`,
                                                    value: totalConsumedUnits[i] ?? 0
                                                  }}
                                                />
                                              </FormGroupField>
                                            </FormGroup>
                                          </InvoiceCol>
                                          <InvoiceCol>
                                            <FormGroup>
                                              <FormGroupLabel>Minimum Charge</FormGroupLabel>
                                              <FormGroupField>
                                                <Field
                                                  name={`${name}.minimum_charge`}
                                                  component={FormField}
                                                  placeholder="Minimum Charge"
                                                  type="number"
                                                  readOnly="readonly"
                                                  input={{
                                                    name: `${name}.minimum_charge`,
                                                    value: minimumCharge[i] ?? 0
                                                  }}
                                                />
                                              </FormGroupField>
                                            </FormGroup>
                                          </InvoiceCol>
                                          <InvoiceCol>
                                            <FormGroup>
                                              <FormGroupLabel>Amount</FormGroupLabel>
                                              <FormGroupField>
                                                <Field
                                                  name={`${name}.amount`}
                                                  component={FormField}
                                                  type="text"
                                                  placeholder="Amount"
                                                  readOnly="readonly"
                                                  input={{
                                                    name: `${name}.amount`,
                                                    value: totalAmount[i] ?? 0
                                                  }}
                                                />
                                                {
                                                  (i != 0)
                                                    ? (
                                                      <Link href={void (0)}
                                                            onClick={() => handleFacilityDelete(fields, i, form)}>
                                                        <CloseCircleOutlineIcon/>
                                                      </Link>
                                                    ) : ''
                                                }
                                              </FormGroupField>
                                            </FormGroup>
                                          </InvoiceCol>
                                        </InvoiceRow>
                                      ))
                                    }
                                    <FormHalfContainer>
                                      <Button
                                        size="sm"
                                        variant="success"
                                        onClick={() => fields.push({})}
                                      >
                                        <PlusCircleOutlineIcon/> <span>Add Property</span>
                                      </Button>
                                      {/*{propertyError ? <Error error={propertyError}/> : ''}*/}
                                    </FormHalfContainer>
                                  </>
                                )}
                              </FieldArray>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="justify-content-end">
                          <Col xl={4}>
                            <FormGroup className="horizontal">
                              <FormGroupLabel>Total: </FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="total"
                                  component={FormField}
                                  type="number"
                                  placeholder="Total"
                                  readOnly="readonly"
                                  input={{ value: total }}
                                />
                              </FormGroupField>
                            </FormGroup>
                            <FormGroup className="horizontal">
                              <FormGroupLabel>Grand Total: </FormGroupLabel>
                              <FormGroupField>
                                <Field
                                  name="grand_total"
                                  component={FormField}
                                  type="number"
                                  readOnly="readonly"
                                  placeholder="Grand Total"
                                  input={{ value: grandTotal }}
                                />
                              </FormGroupField>
                            </FormGroup>
                            {id && <FormGroup className="horizontal">
                              <FormGroupLabel>Payment Status: </FormGroupLabel>
                              { initValue.status && initValue.status[0] && initValue.status[0].value === 'unpaid' ?
                              <FormGroupField>
                                <Field
                                  name="status"
                                  component={renderSelectField}
                                  options={[{
                                    value: 'unpaid',
                                    label: 'Unpaid'
                                  }, {
                                    value: 'paid',
                                    label: 'Paid'
                                  }]}
                                  // onSelectChange={handleFacilityChange}
                                  placeholder="Select status"
                                />
                              </FormGroupField>
                                : <FormGroupField>
                                  <input readOnly="readonly" value='Paid' style={{backgroundColor:"background-color: #f6f6f6;"}} />
                                </FormGroupField>
                              }
                            </FormGroup>
                            }

                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <FormButtonToolbar>
                      <Button variant="primary" type="submit">Submit</Button>
                      <Button variant="secondary" type="button" onClick={reset}>
                        Cancel
                      </Button>
                    </FormButtonToolbar>
                  </FormContainer>
                );
              }}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default CreateInvoice;

const InvoiceRow = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const InvoiceCol = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 5px;

    &:last-child {
        padding-right: 0;
    }

    @media screen and (min-width: 1200px) {
        width: 13%;
    }
`;
