/* eslint-disable */

import { get } from '../../../utils/api/base';
import {  GET_USER_DETAILS_BY } from '../../App/Router/api_routs';
import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';
import { UserRoles } from '../../../shared/constants/userroles';

const getUserDetailsBy = (url) => {
  const auth = localStorage.getItem('pmf') ? JSON.parse(localStorage.getItem('pmf')) : '';
  const token = auth?.accessToken;
  return get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
};
const checkAlreadyExist = async (api_url, values) => {
  const url = api_url;
  let finalUrl = url + values.primary_email;
  if (values.hasOwnProperty('id')) {
    finalUrl = url + values.id;
  }
  const response = await getUserDetailsBy(finalUrl);
  const user_details = response?.data?.user_details;
  return !!((user_details && !values.hasOwnProperty('id')) ||
    (user_details && values.hasOwnProperty('id') && user_details.id === values.id
      && user_details.email !== values.primary_email && ((await getUserDetailsBy(url + values.primary_email))?.data?.user_details)));
};

const validate = async (values) => {
  await sleep(100);
  const errors = {};
  if (!values.transaction_type) {
    errors.transaction_type = 'Transaction Type field is required.';
  }
  if (!values.transaction_category_id) {
    errors.transaction_category_id = 'Transaction Category field is required.';
  }
  if (!values.property_id) {
    errors.property_id = 'Name field is required.';
  }
  if (!values.unit_id) {
    errors.unit_id = 'Name field is required.';
  }
  if (!values.amount) {
    errors.amount = 'Amount field is required.';
  }

  return errors;
};

export default validate;
