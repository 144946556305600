import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Visits from './components/Visits';
import Properties from './components/Properties';
import Tenants from './components/Tenants';
import Invoices from './components/Invoices';
import Transactions from './components/Transactions';
import TotalPageViews from './components/TotalPageViews';
import NewUsers from './components/NewUsers';
import BounceRate from './components/BounceRate';
import ABTestingAnalytics from './components/ABTestingAnalytics';
import BounceRateArea from './components/BounceRateArea';
import VisitorsSessions from './components/VisitorsSessions';
import SalesStatistic from './components/SalesStatistic';
import BudgetStatistic from './components/BudgetStatistic';
import AudienceByCountry from './components/AudienceByCountry';
import BestSellingRegions from './components/BestSellingRegions';
import GoalsCompletion from './components/GoalsCompletion';
import { get } from '../../../utils/api/base';
import { DASHBOARD } from '../../App/Router/api_routs';
import { getAccessToken } from '../../../utils/helpers';
import TransactionAnalytics from './components/TransactionAnalytics';

const PMSDashboard = () => {
  const { t } = useTranslation('common');
  const rtl = useSelector(state => state.rtl);
  const token = getAccessToken();
  const [dashboard, setDashboard] = useState([]);

  useEffect(() => {
    get(DASHBOARD, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }).then(function (response) {
      setDashboard(response.data);
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('pms_dashboard.page_title')}</h3>
        </Col>
      </Row>
      <Row>
        <Properties property={dashboard.totalProperty} dir={rtl.direction} />
        <Tenants tenant={dashboard.totalTenant} dir={rtl.direction} />
        <Invoices invoice={dashboard.totalInvoice} dir={rtl.direction}/>
        <Transactions transaction={dashboard.totalTransaction} dir={rtl.direction}/>
        {/*<Visits />*/}
        {/*<TotalPageViews />*/}
        {/*<NewUsers />*/}
        {/*<BounceRate />*/}
      </Row>
      <Row>
        <TransactionAnalytics dir={rtl.direction} transactions={dashboard.transactions} />
        {/*<ABTestingAnalytics dir={rtl.direction} />
        <BounceRateArea dir={rtl.direction} />
        <VisitorsSessions dir={rtl.direction} />
        <SalesStatistic />
        <BudgetStatistic />
        <AudienceByCountry />
        <BestSellingRegions />
        <GoalsCompletion />*/}
      </Row>
    </Container>
  );
};

export default PMSDashboard;
