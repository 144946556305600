import {
  ELECTRICITIES_USERS_UNITS,
  FACILITIES,
  GET_COUNTRIES,
  GET_DOCUMENT_TYPES,
  LEASE_UNITS,
  GET_LEASED_PROPERTY_BY_PROPERTY_UNIT,
  GET_PROPERTIES,
  GET_PROVINCES,
  GET_ROLES,
  GET_SETTINGS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_BY,
  INVOICES,
  IS_ELECTRICITY_FICILITY,
  IS_WATER_FICILITY,
  PROPERTY_TYPES,
  PROPERTY_UNITS,
  TRANSACTION_CATEGORIES_BY_TYPE,
  GET_LEASED_PROPERTY, GET_USERS, PAYMENT_METHOD
} from '../../containers/App/Router/api_routs';
import { get } from '../../utils/api/base';
import { generateFormInitialValue, ucFirst } from './index';
import { UserRoles } from '../constants/userroles';
import { range } from '../../shared/helpers';
import { Status } from '../constants/commonConstnt';

export const getCountriesList = async (token, source, setCountries) => {
  await get(GET_COUNTRIES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const countryOptions = [];
      const countryData = response?.data?.countries;
      if (countryData) {
        for (const i in countryData) {
          countryOptions[i] = {
            value: countryData[i].num_code,
            label: countryData[i].en_short_name,
          };
        }
      }
      setCountries(countryOptions);
    });
};

export const getProvincesList = async (token, source, setProvinces) => {
  await get(GET_PROVINCES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const provinceOptions = [];
      const provinceData = response?.data?.provinces;
      if (provinceData) {
        for (const j in provinceData) {
          provinceOptions[j] = {
            value: provinceData[j].province_number,
            label: provinceData[j].province_name,
          };
        }
      }
      setProvinces(provinceOptions);
    });
};

export const getRolesList = async (token, source, setRoles, setSelectedRoleOption, setShowSection) => {
  await get(GET_ROLES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const rolesOptions = [];
      const rolesData = response?.data?.roles?.data;
      if (rolesData) {
        for (const l in rolesData) {
          rolesOptions[l] = {
            value: rolesData[l].id,
            label: rolesData[l].name,
          };
        }
      }
      setRoles(rolesOptions);
      setSelectedRoleOption({
        value: '3',
        label: 'tenant',
      });
      setShowSection(true);
    });
};

export const getDocumentTypeList = async (token, source, setDocumentTypes) => {
  await get(GET_DOCUMENT_TYPES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const documentTypeOptions = [];
      const documentTypeData = response?.data?.document_types?.data;
      if (documentTypeData) {
        for (const k in documentTypeData) {
          documentTypeOptions[k] = {
            value: documentTypeData[k].id,
            label: documentTypeData[k].document_type,
          };
        }
      }
      setDocumentTypes(documentTypeOptions);
    });
};

export const getPropertyTypes = async (token, source, setPropertyTypes) => {
  await get(PROPERTY_TYPES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    },
    cancelToken: source.token
  })
    .then((response) => {
      let propertyTypeOptions = [{
        value: '',
        label: 'Property Type'
      }];
      let propertyTypeData = response?.data?.property_types?.data;
      if (propertyTypeData) {
        for (let j in propertyTypeData) {
          let index = parseInt(j) + 1;
          propertyTypeOptions[index] = {
            value: propertyTypeData[j].id,
            label: propertyTypeData[j].property_type
          };
        }
      }
      setPropertyTypes(propertyTypeOptions);
    });
};

export const getPropertyList = async (token, source, setProperties) => {
  await get(GET_PROPERTIES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const propertiesOptions = [{
        value: '',
        label: 'Property'
      }];
      const propertiesData = response?.data?.properties?.data;
      if (propertiesData) {
        for (const l in propertiesData) {
          let index = parseInt(l) + 1;
          propertiesOptions[index] = {
            value: propertiesData[l].id,
            label: propertiesData[l].name,
          };
        }
      }
      setProperties(propertiesOptions);
    });
};

export const getLeasedPropertyList = async (token, source, setProperties) => {
  await get(GET_LEASED_PROPERTY, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
    cancelToken: source.token,
  })
    .then((response) => {
      const propertiesOptions = [{
        value: '',
        label: 'Select Property'
      }];
      const propertiesData = response?.data?.property_leases.data;
      if (propertiesData) {
        for (const l in propertiesData) {
          let index = parseInt(l) + 1;
          propertiesOptions[index] = {
            value: propertiesData[l].id,
            label: propertiesData[l].name,
          };
        }
      }
      setProperties(propertiesOptions);
    });
};

export const getUserDetails = async (token, id, source, setShowSection, setInitValue) => {
  await get(GET_USER_DETAILS + `${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    },
    cancelToken: source.token
  })
    .then((response) => {
      let userData = response?.data?.user_details;
      //let userObjData = response?.data;
      const convertSelectDefault = {
        primary_country: 'primary_country_id',
        secondary_country: 'secondary_country_id',
        primary_province: 'primary_state_id',
        secondary_province: 'secondary_state_id',
      };
      const mapValue = {
        primary_country: 'en_short_name',
        secondary_country: 'en_short_name',
        primary_province: 'province_name',
        secondary_province: 'province_name',
      };
      const users = userData.users;
      delete userData.users;
      userData.role = {
        value: users.role,
        label: users.user_role.name
      };
      let data = generateFormInitialValue(userData, convertSelectDefault, mapValue);
      let documents = [];
      for (const key in data.documents) {
        documents[key] = generateFormInitialValue(data.documents[key], { document_types: 'document_type' }, { document_types: 'document_type' });
      }
      data.documents = documents;

      setShowSection(false);
      if (data.role.value === UserRoles.TENANT) {
        setShowSection(true);
      }
      setInitValue(data);
    });
};

export const getUnitList = async (token, propertyId, index, units, setUnits, leasedOnly = true, nonLeased = false) => {
  let unitURL = PROPERTY_UNITS;
  if (propertyId) {
    unitURL += `?property_id=${propertyId}&leased=${leasedOnly}&nonLeased=${nonLeased}`;
  } else {
    unitURL += `?leased=${leasedOnly}&nonLeased=${nonLeased}`;
  }
  await get(unitURL, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then((response) => {
      const unitOptions = [{
        value: '',
        label: 'Select Unit'
      }];
      const unitData = response?.data?.units?.data;
      if (unitData) {
        for (const l in unitData) {
          let index = parseInt(l) + 1;
          unitOptions[index] = {
            value: unitData[l].id,
            label: unitData[l].unit_name,
          };
        }
      }
      units[index] = unitOptions;
      setUnits(units);
    });
  return units;
};

export const setInitSettingsValue = async (token, source, setInitValue) => {
  await get(GET_SETTINGS, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      cancelToken: source.token
    },
  })
    .then((response) => {
      const options = [];
      const optionsData = response?.data?.settings;
      if (optionsData) {
        for (const i in optionsData) {
          options[optionsData[i].config_title] = optionsData[i].config_value;
          if (optionsData[i].config_value_url !== undefined) {
            options[optionsData[i].config_title + '_url'] = optionsData[i].config_value_url;
          }
        }
      }
      let data = { ...options };
      setInitValue(data);
    });
};

export const getSettings = (token, source) => {
  return get(GET_SETTINGS, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      cancelToken: source.token
    },
  });
};

export const getSettingValue = (data, config_key) => {
  if (data) {
    for (let i in data) {
      if (data[i].config_title == config_key) {
        return data[i].config_value_url ? data[i].config_value_url : data[i].config_value;
      }
    }
  }
  return '';
};

export const getFacilityList = async (token, source, user_id, set) => {
  await get(FACILITIES, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      cancelToken: source.token
    },
  })
    .then((response) => {
      const facilityOptions = [];
      const facilityData = response?.data?.facilities;
      if (facilityData) {
        for (const l in facilityData) {
          facilityOptions[l] = {
            value: facilityData[l].id,
            label: facilityData[l].facility,
          };
        }
      }
      set(facilityOptions);
    });
};

export const getRentByPropertyUnit = async (token, property_id, unit_id, set, setUser) => {
  let url = GET_LEASED_PROPERTY_BY_PROPERTY_UNIT + '/' + property_id + '/' + unit_id;
  let rent = 0;
  await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
    .then((response) => {
      const options = [];
      let lease = response?.data?.property_lease;
      rent = lease?.amount;
      set(rent);
      setUser(lease?.user_id);
    });
  return rent;
};

export const getInvoiceDetail = async (token, id, source, setInitValue,
  currentUnit,
  setCurrentUnit,
  previousUnit,
  setPreviousUnit,
  totalConsumedUnits,
  setTotalConsumedUnits,
  totalAmount,
  setTotalAmount,
  setRent,
  setTotal,
  setGrandTotal,
  setUserId) => {
  await get(INVOICES + `/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    },
    cancelToken: source.token
  })
    .then((response) => {
      let invoiceData = response?.data?.invoice;
      const convertSelectDefault = {
        property: 'property_id',
        unit: 'unit_id'
      };
      const mapValue = {
        property: 'name',
        unit: 'unit_name',
      };
      let status = invoiceData['status'];
      let data = generateFormInitialValue(invoiceData, convertSelectDefault, mapValue);
      data.status = [{
        value: status,
        label: ucFirst(status)
      }];
      data.property = data.property_id;
      data.unit = data.unit_id;
      delete data['property_id'];
      delete data['unit_id'];
      let facilities = [];
      let cu = currentUnit;
      let pu = previousUnit;
      let tcu = totalConsumedUnits;
      let ta = totalAmount;
      for (const key in invoiceData.details) {
        const convertSelectDefault = {
          facility: 'facility_id',
          type: 'facility_type'
        };
        const mapValue = {
          facility: 'facility',
        };
        facilities[key] = generateFormInitialValue(data.details[key], convertSelectDefault, mapValue);
        cu[key] = invoiceData.details[key].current_unit;
        setCurrentUnit(cu);
        pu[key] = invoiceData.details[key].previous_unit;
        setPreviousUnit(pu);
        tcu[key] = invoiceData.details[key].total_consumed_unit;
        setTotalConsumedUnits(tcu);
        ta[key] = invoiceData.details[key].amount;
        setTotalAmount(ta);
      }
      delete data['details'];
      data.facilities = facilities;
      setRent(data.rent);
      setInitValue(data);
      setUserId(data.user_id);
      const total = totalAmount.length > 1 ? totalAmount.reduce((a, b) => a + b) : totalAmount;
      setTotal(total);
      setGrandTotal(parseFloat(total) + parseFloat(data.rent));
    });
};

export const getInvoice = async (id, token, source) => {
  let invoiceData = {};
  await get(INVOICES + `/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    },
    cancelToken: source.token
  })
    .then((response) => {
      invoiceData = response?.data?.invoice;
    });
  return invoiceData;
};

export const getSettingsValue = (key) => {
  let settings = localStorage.getItem('pmfSettings');
  if (settings) {
    settings = JSON.parse(settings);
    return settings[key] ?? '';
  }
  return '';
};

export const getTransactionCategory = async (selectedOption, token, setCategories) => {
  let transactionCategory = await get(TRANSACTION_CATEGORIES_BY_TYPE + '/' + selectedOption.value, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
  let options = [];
  if (transactionCategory) {
    transactionCategory = transactionCategory.data.transaction_categories;
    for (const i in transactionCategory) {
      options[i] = {
        value: transactionCategory[i].id,
        label: transactionCategory[i].name,
      };
    }
    setCategories(options);
  }
  return options;
};

export const getCurrentUserDetails = async (token, user) => {
  return await get(GET_USER_DETAILS_BY + `${user.userData.id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
};

export const getUsers = async (token, set, params) => {
  let url = GET_USERS;
  if (set) {
    url += params;
  }
  let results = await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
  let users = [];
  if (results) {
    let options = [];
    let data = results?.data?.users?.data;
    users = data;
    for (const i in data) {
      options[i] = {
        value: data[i].id,
        label: data[i].name,
      };
    }
    if (set) {
      set(options);
    }
  }
  return users;
};

export const utilities = async (token, setData, setTotalData, setIsFetching, params) => {
  let urlParams = params ?? '';
  const result = await get(FACILITIES + urlParams, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }
  });
  let facilityData = [];
  if (result) {
    facilityData = result?.data?.facilities.data;
    if (facilityData) {
      facilityData.map((item) => {
        item.status = Status[item.status];
      });
    }
    if(setData){
      setData(facilityData);
    }
    if(setTotalData){
      setTotalData(result?.data?.facilities?.total);
    }
    if(setIsFetching){
      setIsFetching(false);
    }
  }
  return facilityData;
};

export const paymentMethods = async (token, setData) => {
  const result = await get(PAYMENT_METHOD, {
    headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' }
  })
  let paymentMethodData = [];
  if (result) {
    paymentMethodData = result?.data?.paymentMethods.data;
    if (paymentMethodData) {
      let options = [];
      for (const i in paymentMethodData) {
        options[i] = {
          value: paymentMethodData[i].id,
          label: paymentMethodData[i].name,
        };
      }
      if (setData) {
        setData(options);
      }
    }
  }
  return paymentMethodData;
};
