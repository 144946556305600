import { React, useState, useEffect, useMemo } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { deleteRequest, get } from '../../utils/api/base';
import { PAYMENT_METHOD } from '../App/Router/api_routs';
import { getAccessToken } from '../../utils/helpers';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import ListTable from './Components/list';
import { Button } from '@/shared/components/Button';
import ConfirmBox from '../../shared/components/Modal/confirm';
import { BasicNotification, useNotification } from '../../shared/components/Notification';
import { useSelector } from 'react-redux';
import {Status} from "../../shared/constants/commonConstnt";
import { useHistory } from 'react-router';


export const PaymentMethod = () => {
    const module = "Payment Method";
    const token = getAccessToken();
    const [isFetching, setIsFetching] = useState(true);
    const [data, setData] = useState({});
    const [totalData, setTotalData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
    const history = useHistory();

    const loadData = async () => {
        let urlParams = `?page=${currentPage}&limit=${pageSize}`;
        const result = await get(PAYMENT_METHOD + urlParams, {
            headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' }
        })
        if (result) {
            setData([]);//set data does not worked unless set this empty.
            let paymentMethodData = result?.data?.paymentMethods.data;
            if(paymentMethodData){
                paymentMethodData.map((item)=>{
                    item.status = Status[item.status];
                });
            }
            setData(paymentMethodData);
            setTotalData(result?.data?.paymentMethods?.total);
            setIsFetching(false);
        }
    }

    useEffect(() => {
        loadData();
    }, [currentPage, pageSize]);

    const editData = (e, id) => {
        history.push(`edit/${id}`);
    }

    const deleteClickHandle = (e, pTypeId) => {
        setShowHideConfirmBox(pTypeId);
    }

    const { rtl, theme } = useSelector(state => ({
        rtl: state.rtl,
        theme: state.theme,
    }));

    const show = (color, message) => useNotification({
      notification() {
        return (
          <BasicNotification
            color={color}
            title="Payment Method"
            message={message}
            theme={theme}
          />
        );
      },
      position: 'right-up'
    });

    const deletePaymentMethod = (facility) => {
        deleteRequest(PAYMENT_METHOD + '/' + facility, {
            headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' }
        }).then(function (response) {
            if (response?.status == 200) {
                loadData();
                setShowHideConfirmBox(false);
                show('success', response.data.message);
            } else {
                show('info', response.data.errors.join('<br/>'));
            }
        }).catch(function (e) {
            show('info', e?.message);
        });
    }

    const columns = useMemo(
        () => [
            {
                Header: 'S.N.',
                Cell: (cell) => (<>{cell.row.index + 1}</>)
            },
            {
                Header: 'Payment Method',
                accessor: 'name'
            },
            {
                Header: 'Payment Details',
                accessor: 'details'
            },
            {
                Header: 'Status',
                accessor: 'status'
            },
            {
                Header: "Actions",
                accessor: "[actionButtons]",
                Cell: (cellObj) => (
                    <>
                        <Button variant="outline-primary" size="sm" marginBottom="0" onClick={(e)=>editData(e, cellObj.row.original.id)}>Edit</Button>
                        <Button variant="outline-danger" size="sm" marginBottom="0" onClick={(e) => deleteClickHandle(e, cellObj.row.original.id)} >Delete</Button>
                    </>
                )
            }
        ]
    );


    const pageSettings = ({
        total: totalData,
        currentPage,
        limit: pageSize,
        customPagination: true,
    });

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{module}</h3>
                </Col>
            </Row>
            {showHideConfirmBox && (
                <Row>
                    <ConfirmBox
                        color='primary'
                        message='Are you sure you want to delete?'
                        showHide={showHideConfirmBox}
                        onChangeShowHide={setShowHideConfirmBox}
                        callbackConfirm={deletePaymentMethod} />
                </Row>
            )}
            <Row>
                {isFetching && (
                    <div className="text-center">
                        <TableSpinner animation="border" />
                    </div>
                )}
                {!isFetching && (
                    <Col md={12}>
                        <ListTable
                            reactTableData={{ tableHeaderData: columns, tableRowsData: data, pageSettings: pageSettings }}
                            module={module}
                            setPage={(page) => setCurrentPage(page)}
                            setLimit={(limit) => setPageSize(limit)}
                            showNotification={show}
                        />
                    </Col>
                )}
            </Row>
        </Container>
    )
}


const TableSpinner = styled(Spinner)`
  color: ${colorAccent};
`;
