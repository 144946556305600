import { Status } from '../constants/commonConstnt';

const ligthTheme = {
  backgroundColor: 'white',
  color: '#646777',
};

const darkTheme = {
  backgroundColor: '#2e2c34',
  color: '#dddddd',
};

export const themes = {
  ligthTheme,
  darkTheme,
};

export const emailPatter = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
export const urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;

export const toPercent = (decimal, fixed = 0) => `${decimal.toFixed(fixed)}%`;

const getTooltipStyles = (themeName, type) => {
  switch (themeName) {
    case 'dark': {
      const {
        backgroundColor,
        color
      } = darkTheme;
      return {
        contentStyle: { backgroundColor },
        itemStyle: type === 'defaultItems' ? null : { color },
      };
    }
    case 'light': {
      return ligthTheme;
    }
    default:
      return ligthTheme;
  }
};

export default getTooltipStyles;

export const firstLetterToUpperCase = (str) => {
  if (!str) return 'Something went wrong';

  const firstLetterUpperCase = str[0].toUpperCase();
  return `${firstLetterUpperCase}${str.slice(1)}`;
};

export const getKeyByValue = (object, value) => Object.keys(object)
  .find(key => object[key] === value);

export const ucFirst = str => str[0].toUpperCase() + str.slice(1);

export const generateFormInitialValue = (rawData, convertSelectDefault = [], mapValue = []) => {
  const data = [];
  Object.keys(rawData)
    .forEach((key) => {
      if (rawData[key] != null) {
        data[key] = rawData[key];
        if (key === 'status') {
          data[key] = {
            value: parseInt(rawData[key]),
            label: Status[rawData[key]]
          };
        }
        if (Object.values(convertSelectDefault)
          .indexOf(key) > -1) {
          if(rawData[getKeyByValue(convertSelectDefault, key)]) {
            data[key] = {
              value: rawData[key],
              label: rawData[getKeyByValue(convertSelectDefault, key)][mapValue[getKeyByValue(convertSelectDefault, key)]],
            }
          }
        }
      }
    });
  Object.keys(data)
    .forEach((key) => {
      if (getKeyByValue(convertSelectDefault, key) !== undefined) {
        delete data[getKeyByValue(convertSelectDefault, key)];
      }
    });
  return data ? { ...data } : null;
};

export const range = (start, end, step) => {
  const range = [];
  const typeofStart = typeof start;
  const typeofEnd = typeof end;

  if (step === 0) {
    throw TypeError('Step cannot be zero.');
  }

  if (typeofStart === 'undefined' || typeofEnd === 'undefined') {
    throw TypeError('Must pass start and end arguments.');
  } else if (typeofStart !== typeofEnd) {
    throw TypeError('Start and end arguments must be of same type.');
  }

  typeof step === 'undefined' && (step = 1);

  if (end < start) {
    step = -step;
  }

  if (typeofStart == 'number') {
    while (step > 0 ? end >= start : end <= start) {
      range.push({
        value: start.toString(),
        label: start.toString(),
      });
      start += step;
    }
  } else if (typeofStart == 'string') {
    if (start.length != 1 || end.length != 1) {
      throw TypeError('Only strings with one character are supported.');
    }

    start = start.charCodeAt(0);
    end = end.charCodeAt(0);

    while (step > 0 ? end >= start : end <= start) {
      range.push(String.fromCharCode({
        value: start.toString(),
        label: start.toString(),
      }));
      start += step;
    }
  } else {
    throw TypeError('Only string and number types are supported');
  }

  return range;
};

export const replaceAll = (str, mapObj) => {
  if (str === undefined) return '';

  const re = new RegExp(Object.keys(mapObj)
    .join('|'), 'gi');
  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
};

export const prepareData = (data, convertIntKeys, excludes = []) => {
  const formData = new FormData();
  let convertData = [];
  Object.keys(data)
    .forEach(function (key) {
      if (!excludes.includes(key)) {
        if (typeof (data[key]) === 'object' && (data[key]) !== null) {
          convertData[key] = data[key].value;
          if (convertData[key] === undefined) {
            convertData[key] = data[key];
          }
          if (convertIntKeys.includes(key)) {
            convertData[key] = parseInt(data[key].value);
            if (2 === convertData[key] && 'status' === key) {
              convertData[key] = 0;
            }
          }
          if (data[key]['file'] !== undefined) {
            convertData[key] = data[key].file;
          }
        } else {
          convertData[key] = data[key];
          if (convertIntKeys.includes(key)) {
            convertData[key] = parseInt(data[key]);
          }
        }
      }
      formData.append(key, convertData[key]);
    });
  return {
    data: convertData,
    form_data: formData
  };
};

export const encodeDataToURL = (data) => {
  return Object
    .keys(data)
    .map(value => `${value}=${encodeURIComponent(data[value])}`)
    .join('&');
};

export const rand = (min, max = 1000) => {
  return Math.floor(Math.random()
    * (max - min + 1)) + min;
};
