import styled from 'styled-components';

export const FormWrap = styled.div`
    position: relative;
    .align-center {
        align-items: center;
    }
`;

export const RepeaterWrap = styled.div`
    position: relative;
    
    .align-center {
        align-items: center;
        
        .btn {
            margin-bottom: 0!important;
        }
    }
    
    a {
        cursor: pointer;
    }

    .bg-alternate-color:nth-child(even) {
        background-color: #f5f5f5;
        padding-top: 20px;
        margin-bottom: 20px;

        input {
            background-color: #fff;
            padding: 2px 8px;
            min-height: 38px;
        }
    }
`;
