import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import ReactTableCustomizer from '@/shared/components/table/components/ReactTableCustomizer';
import {
    Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import styled from 'styled-components';
import { SearchInput, SearchSelect, SearchWrap } from '../../../shared/components/SearchStyles';
import axios from 'axios';
import { getPropertyList, getPropertyTypes } from '../../../shared/helpers/getDatas';
import { getAccessToken } from '../../../utils/helpers';
import renderSelectField from '@/shared/components/form/Select';
import { get } from '../../../utils/api/base';
import { GET_USERS } from '../../App/Router/api_routs';
import { encodeDataToURL } from '../../../shared/helpers';

const reorder = (rows, startIndex, endIndex) => {
    const result = Array.from(rows);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const ListTable = ({ reactTableData, module, setPage, setLimit }) => {
    const token = getAccessToken();
    const [rows, setData] = useState(reactTableData.tableRowsData);
    const [isEditable, setIsEditable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);
    const [isSortable, setIsSortable] = useState(false);
    const [isDisabledDragAndDrop, setIsDisabledDragAndDrop] = useState(false);
    const [isDisabledEditable, setIsDisabledEditable] = useState(false);
    const [isDisabledResizable, setIsDisabledResizable] = useState(false);
    const [withDragAndDrop, setWithDragAndDrop] = useState(false);
    const [withPagination, setWithPaginationTable] = useState(true);
    const [withSearchEngine, setWithSearchEngine] = useState(false);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [properties, setProperties] = useState([]);
    const [search, setSearch] = useState([]);
    const [pageSettings, setPageSettings] = useState(reactTableData.pageSettings);
    const {currentPage, total, limit, customPagination} = reactTableData.pageSettings;

    const handleClickIsEditable = () => {
        if (!withDragAndDrop) setIsDisabledResizable(!isDisabledResizable);
        setIsResizable(false);
        setIsEditable(!isEditable);
    };
    const handleClickIsResizable = () => {
        setIsEditable(false);
        setWithDragAndDrop(false);
        setIsDisabledDragAndDrop(!isDisabledDragAndDrop);
        setIsDisabledEditable(!isDisabledEditable);
        setIsResizable(!isResizable);
    };
    const handleClickIsSortable = () => {
        setIsSortable(!isSortable);
    };
    const handleClickWithDragAndDrop = () => {
        if (!isEditable) setIsDisabledResizable(!isDisabledResizable);
        setIsResizable(false);
        setWithDragAndDrop(!withDragAndDrop);
    };
    const handleClickWithPagination = () => {
        setWithPaginationTable(!withPagination);
    };
    const handleClickWithSearchEngine = () => {
        setWithSearchEngine(!withSearchEngine);
    };

    const updateDraggableData = (result) => {
        const items = reorder(
            rows,
            result.source.index,
            result.destination.index,
        );
        setData(items);
    };

    const updateEditableData = (rowIndex, columnId, value) => {
        setData(old => old.map((item, index) => {
            if (index === rowIndex) {
                return {
                    ...old[rowIndex],
                    [columnId]: value,
                };
            }
            return item;
        }));
    };

    const tableConfig = {
        isEditable,
        isResizable,
        isSortable,
        withDragAndDrop,
        withPagination,
        withSearchEngine,
        manualPageSize: [10, 20, 30, 40],
        placeholder: 'Search by First name...',
        pageSettings: pageSettings,
    };

    const onChange = async (e) => {
        let searchList = search;
        searchList[e.target.name] = e.target.value;
        setSearch(searchList);
        const searchFields = encodeDataToURL(search);
        const urlParams = `?page=${currentPage}&limit=${limit}&${searchFields}`;
        const result = await get(GET_USERS + urlParams, {
            headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' }
        })
        if (result) {
            setData(result?.data?.users?.data);
            let tot = result?.data?.users?.total;
            setPageSettings({currentPage, total:tot, limit, customPagination})
        }
    };
    const handleSelectChange = (selectedOption, e) => {
        let se = {target:{name: e.name, value:selectedOption.value}};
        onChange(se);
    };

    useEffect(async () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        await getPropertyTypes(token, source, setPropertyTypes);
        await getPropertyList(token, source, setProperties);
        return () => {
            source.cancel();
        };
    }, [rows]);

    return (
        <Col md={12} lg={12}>
            <Card>
                <CardBody>
                    <HeaderWrap>
                        <CardTitleWrap>
                            <CardTitle>{module} List</CardTitle>
                        </CardTitleWrap>
                    </HeaderWrap>
                    <SearchWrap className="row">
                        <Container>
                            <Row>
                                <Col md={4}>
                                    <SearchInput
                                    type="text"
                                    name="name"
                                    placeholder="Primary user name"
                                    onChange = {(e)=>onChange(e)}
                                    value = {search['name']}
                                    />
                                </Col>
                              <Col md={4}>
                                  <SearchInput
                                  type="text"
                                  name="primary_contact_number"
                                  placeholder="Primary contact number"
                                  onChange = {(e)=>onChange(e)}
                                  value = {search['primary_contact_number']}
                                  />
                              </Col>
                              <Col md={4}>
                                  <SearchInput
                                  type="text"
                                  name="address1"
                                  placeholder="Location"
                                  onChange = {(e)=>onChange(e)}
                                  value = {search['address1']}
                                  />
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col md={4}>
                                  <SearchSelect
                                    name="property_id"
                                    onChange ={handleSelectChange}
                                    options={properties}
                                    placeholder="Property"
                                    //value = {selectValue}
                                  />
                              </Col>
                              <Col md={4}>
                                  <SearchSelect
                                    name="property_type_id"
                                    onChange ={handleSelectChange}
                                    options={propertyTypes}
                                    placeholder="Property Type"
                                    //value = {selectValue}
                                  />
                              </Col>
                              <Col md={4}>
                                  <SearchSelect
                                    name="property_status"
                                    onChange ={handleSelectChange}
                                    options={[{value:'', label:'Status'},{value:1, label:'Active'}, {value:2, label:'Inactive'}]}
                                    placeholder="Property Status"
                                  />
                              </Col>
                            </Row>
                        </Container>
                    </SearchWrap>
                    <ReactTableBase
                        key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
                        columns={reactTableData.tableHeaderData}
                        data={rows}
                        updateEditableData={updateEditableData}
                        updateDraggableData={updateDraggableData}
                        tableConfig={tableConfig}
                        setPage={setPage}
                        setLimit={setLimit}
                    />
                </CardBody>
            </Card>
        </Col>
    );
};

ListTable.propTypes = {
    reactTableData: PropTypes.shape({
        tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
        })),
        tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
        defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
        defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired,
    setPage: PropTypes.func,
    setLimit: PropTypes.func,
    showNotification: PropTypes.func,
};
ListTable.defaultProps = {
    setPage: () => { },
    setLimit: () => { },
    showNotification: () => { }
};
export default ListTable;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`;

// endregion
