import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PermissionRoute } from '../../../../shared/components/ProtectedRoute';
import { UserRoles } from '../../../../shared/constants/userroles';
import { InvoiceList } from '../../../Invoice';
import CreateInvoice from '../../../Invoice/Components/add';
import InvoiceTemplate from '../../../Invoice/Components/pdf/InvoiceTemplate';

export default () => (
  <Switch>
    <PermissionRoute AllowRoles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]}>
      <Route path="/invoice/list" component={InvoiceList}/>
      <Route path="/invoice/create" component={CreateInvoice}/>
      <Route path="/invoice/edit/:id" component={CreateInvoice}/>
      <Route path="/invoice/view/:id" component={InvoiceTemplate}/>
    </PermissionRoute>
  </Switch>
);
