import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonGroup, Col, Container, Row, Spinner } from 'react-bootstrap';
import { deleteRequest, get } from '../../utils/api/base';
import { INVOICES } from '../App/Router/api_routs';
import { getAccessToken } from '../../utils/helpers';
import { colorAccent } from '@/utils/palette';
import styled from 'styled-components';
import ListTable from './Components/list';
import ConfirmBox from '../../shared/components/Modal/confirm';
import { Button } from '@/shared/components/Button';
import { useSelector } from 'react-redux';
import { useNotification, BasicNotification } from '../../shared/components/Notification';
import { DownloadButtons } from '../../shared/components/form/CustomStyleElements';
import { State } from './Components/pdf/context/stateContext';
import Invoice from './Components/pdf/InvoiceTemplate';
import { useReactToPrint } from 'react-to-print';

export const InvoiceList = () => {
  const module = 'Invoice';
  const token = getAccessToken();
  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState({});
  const [totalData, setTotalData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showHideConfirmBox, setShowHideConfirmBox] = useState(false);
  const {
    componentRef,
    handlePrint,
  } = useContext(State);

  const history = useHistory();
  const editData = (id) => {
    let path = `edit/${id}`;
    history.push(path);
  };

  const {
    rtl,
    theme
  } = useSelector(state => ({
    rtl: state.rtl,
    theme: state.theme,
  }));

  const show = (color, message) => useNotification({
    notification() {
      return (
        <BasicNotification
          color={color}
          title="User"
          message={message}
          theme={theme}
        />
      );
    },
    position: 'right-up'
  });

  const deleteClickHandle = (e, invoiceId) => {
    setShowHideConfirmBox(invoiceId);
  };

  const deleteInvoice = (userId) => {
    deleteRequest(INVOICES + '/' + userId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    })
      .then(function (response) {
        if (response?.status == 200) {
          loadData();
          setShowHideConfirmBox(false);
          show('success', response.data.message);
        } else {
          show('info', response.data.errors.join('<br/>'));
        }
      })
      .catch(function (e) {
        show('info', e?.message);
      });
  };

  const loadData = async () => {
    setIsFetching(true);
    let urlParams = `?page=${currentPage}&limit=${pageSize}`;
    const result = await get(INVOICES + urlParams, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json'
      }
    });
    if (result) {
      setData([]);//set data does not worked unless set this empty.
      setData(result?.data?.invoices?.data);
      setTotalData(result?.data?.invoices?.total);
      setIsFetching(false);
    }
  };

  const downloadHandle =()=>{
    //<Invoice invoiceId={cellObj.row.original.id} ref={componentRef}/>
    useReactToPrint({
      content: () => componentRef.current,
    })
  };

  const view = (invId) => {
    let path = `view/${invId}`;
    history.push(path);
  }

  useEffect(() => {
    loadData();
  }, [currentPage, pageSize]);

  const columns = useMemo(
    () => [
      {
        Header: 'Invoice Number',
        accessor: 'invoice_number',
      },
      {
        Header: 'Property',
        accessor: 'property.name',
      },
      {
        Header: 'Unit',
        accessor: 'unit.unit_name',
      },
      {
        Header: 'Rent',
        accessor: 'rent',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Actions',
        accessor: '[actionButtons]',
        Cell: (cellObj) => (
          <>
            <Button variant="outline-success" size="sm" marginBottom="0"
                    onClick={() => view(cellObj.row.original.id)}>View</Button>
            <Button variant="outline-primary" size="sm" marginBottom="0"
                    onClick={() => editData(cellObj.row.original.id)}>Edit</Button>
            <Button variant="outline-danger" size="sm" marginBottom="0"
                    onClick={(e) => deleteClickHandle(e, cellObj.row.original.id)}>Delete</Button>
          </>
        )
      },
      /*{
        Header: 'Download',
        accessor: '',
        Cell: (cellObj) => (<DownloadButtons>
          <a className="lnr lnr-printer" href="#"
             onClick={(e) => downloadHandle(e, cellObj.row.original.id, 'print')}></a>
        </DownloadButtons>)
      }*/
    ]
  );

  const pageSettings = ({
    total: totalData,
    currentPage,
    limit: pageSize,
    customPagination: true,
  });

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{module} Information</h3>
        </Col>
      </Row>
      {showHideConfirmBox && (
        <Row>
          <ConfirmBox
            color="primary"
            message="Are you sure you want to delete?"
            showHide={showHideConfirmBox}
            onChangeShowHide={setShowHideConfirmBox}
            callbackConfirm={deleteInvoice}/>
        </Row>
      )}
      <Row>
        {isFetching && (
          <div className="text-center">
            <TableSpinner animation="border"/>
          </div>
        )}
        {!isFetching && (
          <Col md={12}>
            <ListTable
              reactTableData={{
                tableHeaderData: columns,
                tableRowsData: data,
                pageSettings: pageSettings
              }}
              module={module}
              setPage={(page) => setCurrentPage(page)}
              setLimit={(limit) => setPageSize(limit)}
              showNotification={show}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

const TableSpinner = styled(Spinner)`
    color: ${colorAccent};
`;
